/**
 * Interface specific events.
 */
export enum InterfaceEventName {
  ACCOUNT_DROPDOWN_BUTTON_CLICKED = 'Account Dropdown Button Clicked',
  APPROVE_TOKEN_TXN_SUBMITTED = 'Approve Token Transaction Submitted',
  CHAIN_CHANGED = 'Chain Changed',
  CONNECT_WALLET_BUTTON_CLICKED = 'Connect Wallet Button Clicked',
  EXPLORE_BANNER_CLICKED = 'Explore Banner Clicked',
  EXPLORE_SEARCH_SELECTED = 'Explore Search Selected',
  EXPLORE_TOKEN_ROW_CLICKED = 'Explore Token Row Clicked',
  EXTERNAL_LINK_CLICK = 'External Link Click',
  FIAT_ONRAMP_BANNER_CLICKED = 'Fiat Onramp Banner Clicked',
  FIAT_ONRAMP_WIDGET_OPENED = 'Fiat Onramp Widget Opened',
  MINI_PORTFOLIO_TOGGLED = 'Mini Portfolio Drawer Toggled',
  NAVBAR_RESULT_SELECTED = 'Navbar Result Selected',
  NAVBAR_SEARCH_EXITED = 'Navbar Search Exited',
  NAVBAR_SEARCH_SELECTED = 'Navbar Search Selected',
  RISK_CHECKED = 'Risk Checked',
  TOKEN_IMPORTED = 'Token Imported',
  TOKEN_SELECTED = 'Token Selected',
  TOKEN_SELECTOR_OPENED = 'Token Selector Opened',
  UNISWAP_WALLET_MICROSITE_OPENED = 'Uniswap Wallet Microsite Opened',
  UNIWALLET_CONNECT_MODAL_OPENED = 'Uniswap wallet modal opened',
  WALLET_CONNECTED = 'Wallet Connected',
  WALLET_PROVIDER_USED = 'Wallet Provider Used',
  WALLET_SELECTED = 'Wallet Selected',
  WRAP_TOKEN_TXN_INVALIDATED = 'Wrap Token Transaction Invalidated',
  WRAP_TOKEN_TXN_SUBMITTED = 'Wrap Token Transaction Submitted',
  NEW_POSITION_BUTTON_CLICKED = 'New Position button clicked',
}
